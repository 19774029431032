import { MouseEvent } from "react";

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export type AsyncMouseEventHandler<T> = (e: MouseEvent<T>) => void | Promise<void>;

/**
 * From T, pick a set of properties whose values are in the type V
 */
export type PickByValue<T, V> = { [P in keyof T as T[P] extends V ? P : never]: P };
/**
 * From T, pick keys from a set of properties whose values are in the type V
 */
export type KeysByValue<T, V> = keyof PickByValue<T, V>;

export type Override<T, U> = Omit<T, keyof U> & U;

export type One<T> = { [K in keyof T]: K }[keyof T];

export type PartialInArray<T> = T extends Array<infer I> ? Partial<I>[] : Partial<T>;

export type Flags<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export type PartialRequired<T, TRequired extends keyof T = keyof T> = Partial<Pick<T, Exclude<keyof T, TRequired>>> &
  Required<Pick<T, TRequired>>;

export type PartialPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export type Unpromise<T extends Promise<unknown>> = T extends Promise<infer U> ? U : never;

export interface JsonMap extends Record<string, JsonPrimitive | JsonArray | JsonMap> {}
export interface JsonArray extends Array<JsonPrimitive | JsonArray | JsonMap> {}

export type JsonPrimitive = string | number | boolean | null;
export type Json = JsonPrimitive | JsonMap | JsonArray;
export type Primitive = JsonPrimitive | symbol;

/**
 * https://www.ferreira.io/posts/opaque-branded-types-in-typescript
 *
 * Opaque types are primitives that come from external sources which we
 * want to make sure stay unchanged.  We want to make sure that they can
 * be used in a variety of places, but can only be set to by a value
 * from that same external source.  In this way we preserve their
 * formatting or unique value.
 *
 * @param BASE the base type which this type can be read as
 * @param NAME the name of this opaque type, used to make the type unique
 */
export type Opaque<BASE extends Primitive, NAME extends string> = BASE & Readonly<{ _opaqueName: NAME }>;

/**
 * A timezone as provided by the browser.
 */
export type BrowserTimeZone = Opaque<string, "BrowserTimeZone">;
export type ShortTz = Opaque<string, "ShortTz">;
export type LongTz = Opaque<string, "LongTz">;
export type TimeZone = BrowserTimeZone | ShortTz | LongTz;

export type ViewAsVisibility = "private" | "public";

export type AssignmentType = "task" | "habit" | "one-on-one" | "scheduling-link";
export type AssignmentOrEventType = AssignmentType | "event";

export type Nullable<T> = { [K in keyof T]: T[K] | null };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyDefined = Omit<any, undefined | null>;

// Guards

export function notUndefined<T>(x: T | undefined): x is T {
  return undefined !== x;
}

export function notNull<T>(x: T | null): x is T {
  return null !== x;
}

export function notEmpty<T>(x?: T | null): x is T {
  return notUndefined(x) && notNull(x) && (!Array.isArray(x) || !!x.length);
}

export function notFalsy<T>(x: T | null | false | [] | "" | undefined): x is T {
  return !!x;
}

export function isType<T>(x: T | undefined, fn: (x: T) => boolean): x is T {
  return notUndefined(x) && fn(x);
}

export function isSub<T extends P, P>(x: P, fn: (x: P) => boolean): x is T {
  return notNull(x) && notUndefined(x) && fn(x);
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
