import {
  Children,
  Fragment,
  isValidElement,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
  RefCallback,
} from "react";

export function retry(fn: () => Promise<{ default: React.ComponentType<unknown> }>, retries = 5, interval = 1000) {
  return new Promise<{ default: React.ComponentType<unknown> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((err: unknown) => {
        setTimeout(() => {
          if (retries <= 1) return reject(err);
          retry(fn, interval, retries - 1).then(resolve, reject);
        }, interval);
      });
  });
}

export default {
  retry,
};

export function isTextInput(el: HTMLElement) {
  if ("TEXTAREA" === el.nodeName) return true;
  if (
    "INPUT" === el.nodeName &&
    !!el.hasAttribute &&
    el.hasAttribute("type") &&
    ["text", "email", "month", "number", "password", "search", "tel", "time", "url", "week"].includes(
      el.getAttribute("type") || ""
    )
  )
    return true;
  return false;
}

export function isAnchorElMissing(anchorEl) {
  const resolvedAnchorEl = typeof anchorEl === "function" ? anchorEl() : anchorEl;

  if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {
    const box = resolvedAnchorEl.getBoundingClientRect();

    if (process.env.NODE_ENV !== "test" && box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
      return true;
    }
  }
  return false;
}

/**
 * merges refs
 * @param refs refs to merge
 * @returns a singular function ref
 */
export function mergeRefs<T>(...refs: Ref<T>[]): RefCallback<T> {
  return (inst) => {
    refs.forEach((ref) => {
      if (!ref) return;
      switch (typeof ref) {
        case "function":
          ref(inst);
          break;
        default:
          (ref as MutableRefObject<T | null>).current = inst;
          break;
      }
    });
  };
}

export const hasChildren = (element: ReactNode): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);

export const mapChildrenWithFrags = (
  children: ReactNode,
  cb: (child: ReactNode, i: number) => ReactNode,
  baseIterator = 0
) =>
  Children.map(children, (child: ReactElement<PropsWithChildren<{}>>) => {
    const i = baseIterator;
    if (!isValidElement(child)) {
      baseIterator++;
      return cb(child, i);
    }
    if (child.type === Fragment) return <>{mapChildrenWithFrags(child.props.children, cb, baseIterator)}</>;
    else {
      baseIterator++;
      return cb(child, i);
    }
  });
